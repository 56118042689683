import React from 'react';

import './Header.css';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function ReactJS() {
    const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="App">
      <header className="App-header">
        <nav className="nav-container">
          <div className="logo-container">
          <Link to="/" style={{textDecoration: "none"}}>
            <img src={"/main logo.png"} className="App-logo" alt="logo" style={{marginBottom: "-10%"}} />
            <span className="logo-name" style={{fontFamily: "serif"}}>ALGO AVINYA</span>
            </Link>
          </div>
          <ul className="nav-bar" style={{marginTop:  isMobile?"0%":"6%", marginRight: "2%"}}>
          
            <li>
            <Link to="/" style={{textDecoration: "none"}}><a href="#home" style={{fontSize:isMobile?8: 22, padding:isMobile?"0px 0px": "10px 10px"}}>Home</a></Link></li>
            
          </ul>
        </nav>

        <div style={{backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      paddingBottom: "10px",padding: "20px 20px 0px 20px", marginRight: "5%", marginLeft: "5%", marginTop: "5%"}}>
      <h2 style={{textAlign: "center", color: "#800000",  fontSize: isMobile?10:""}}>Why We Use React.js</h2>
      <hr  style={{
    color: 'darkgreen',
    backgroundColor: 'darkgreen',
    height: isMobile?0.5:3.5,
    borderColor : 'darkgreen',
    width: "55%",
     marginBottom:"3%"
}}/>

      <h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
At Algo Avinya, we utilize React.js due to its superior ability to create highly interactive and efficient web applications. Its component-based architecture allows us to develop reusable and modular UI components, promoting consistency and simplifying maintenance. The Virtual DOM in React ensures that updates are fast and only the necessary parts of the application are re-rendered, leading to a smoother user experience.
</h4>
 <h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
React’s rich ecosystem of libraries and tools, including Redux for state management and React Router for routing, allows us to build complex applications efficiently. Developer tools like React Developer Tools streamline debugging and maintenance. Server-side rendering (SSR) improves SEO performance and reduces initial load times, enhancing overall user experience. Furthermore, React's large community and strong backing from Facebook ensure continuous improvements and a wealth of resources.
</h4>
<h2 style={{textAlign: "center", color: "#800000",  fontSize: isMobile?10:""}}>Why We Use React Native</h2>
<hr  style={{
    color: 'darkgreen',
    backgroundColor: 'darkgreen',
    height: isMobile?0.5:3.5,
    borderColor : 'darkgreen',
    width: "55%",
     marginBottom:"3%"
}}/>
<h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>

We choose React Native for mobile application development because it enables cross-platform development with a single codebase for both iOS and Android. This approach reduces development time and ensures a consistent user experience across platforms. React Native’s use of native modules and components provides performance close to native applications, ensuring fast and responsive mobile apps. The hot reloading feature allows developers to see changes in real-time without recompiling the entire application, speeding up the development process.
</h4>
<h3 style={{ fontSize: isMobile?9:""}}>
Benefits for Clients
</h3>
<h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
Using React.js and React Native brings significant benefits to our clients. The reuse of components and code across platforms leads to faster development times and cost savings. Applications built with these technologies are highly performant, providing a seamless user experience that enhances user retention and engagement. The modular nature of React components ensures that applications are scalable and maintainable, allowing for easy updates and feature additions. Server-side rendering with React improves SEO, making web applications more discoverable. The robust and secure nature of these technologies, supported by active communities and continuous improvements, ensures high-quality, reliable software solutions.
</h4>

        </div>
        
      </header>
      
    </div>
  );
}

export default ReactJS;
