import './App.css';
import Header from './Pages/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WebDevelopment from './Pages/WebDevelopment';
import MobileDevelopment from './Pages/MobileDevelopment';
import CreativityInnovation from './Pages/CreativityInnovation';
import ReactJS from './Pages/ReactJS';
import Python from './Pages/Python';
import Sql from './Pages/Sql';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Header />} />
          
        </Routes>
        <Routes>
          <Route path="/webdevelopment" element={<WebDevelopment />} />
        </Routes>
        <Routes>
          <Route path="/mobiledevelopment" element={<MobileDevelopment />} />
        </Routes>
        <Routes>
          <Route path="/cretaivityandinnovation" element={<CreativityInnovation />} />
        </Routes>
        <Routes>
          <Route path="/reactjs" element={<ReactJS />} />
        </Routes>
        <Routes>
          <Route path="/python" element={<Python />} />
        </Routes>
        <Routes>
          <Route path="/sql" element={<Sql />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
