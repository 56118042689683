import React from 'react';

import './Header.css';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function CreativityInnovation() {
    const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="App">
      <header className="App-header">
        <nav className="nav-container">
          <div className="logo-container">
          <Link to="/" style={{textDecoration: "none"}}>
            <img src={"/main logo.png"} className="App-logo" alt="logo" style={{marginBottom: "-10%"}} />
            <span className="logo-name" style={{fontFamily: "serif"}}>ALGO AVINYA</span>
            </Link>
          </div>
          <ul className="nav-bar" style={{marginTop: isMobile?"0%": "6%", marginRight: "2%"}}>
          
            <li>
            <Link to="/" style={{textDecoration: "none"}}><a href="#home" style={{fontSize:isMobile?8: 22, padding:isMobile?"0px 0px": "10px 10px"}}>Home</a></Link></li>
            
          </ul>
        </nav>

        <div style={{backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      paddingBottom: "10px",padding: "20px 20px 0px 20px", marginRight: "5%", marginLeft: "5%", marginTop: "5%"}}>
      <h2 style={{textAlign: "center", color: "#800000", fontSize: isMobile?10:""}}>Creativity And Innovation</h2>
      <hr  style={{
    color: 'darkgreen',
    backgroundColor: 'darkgreen',
    height: isMobile?0.5:3.5,
    borderColor : 'darkgreen',
    width: "55%",
     marginBottom:"3%"
}}/>
            <h4 style={{textAlign: "justify", fontSize: isMobile?8:""}}>Here at "Algo Avinya," we take a more creative and innovative approach to software development than just making it work. Our committed group of engineers, designers, and developers works together harmoniously to create and deliver solutions that not only fulfill but also beyond expectations. We take great satisfaction in creating custom goods that demonstrate our dedication to both technical mastery and creative design. We infuse each project with imaginative solutions that challenge industry conventions and boost user experiences because we strongly emphasize innovation. By employing agile processes and prioritizing state-of-the-art technologies, we guarantee that our apps not only stay up to date with emerging trends but also anticipate them. Our iterative process, which is based on user feedback and prototyping, ensures that every solution we provide is a masterwork of software engineering that is customized to your specific requirements rather than just a software product.
</h4>
        </div>
        
      </header>
      
    </div>
  );
}

export default CreativityInnovation;
