import React from 'react';

import './Header.css';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function Sql() {
    const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="App">
      <header className="App-header">
        <nav className="nav-container">
          <div className="logo-container">
          <Link to="/" style={{textDecoration: "none"}}>
            <img src={"/main logo.png"} className="App-logo" alt="logo" style={{marginBottom: "-10%"}} />
            <span className="logo-name" style={{fontFamily: "serif"}}>ALGO AVINYA</span>
            </Link>
          </div>
          <ul className="nav-bar" style={{marginTop:  isMobile?"0%":"6%", marginRight: "2%"}}>
          
            <li>
            <Link to="/" style={{textDecoration: "none"}}><a href="#home" style={{fontSize:isMobile?8: 22, padding:isMobile?"0px 0px": "10px 10px"}}>Home</a></Link></li>
            
          </ul>
        </nav>

        <div style={{backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      paddingBottom: "10px",padding: "20px 20px 0px 20px", marginRight: "5%", marginLeft: "5%", marginTop: "5%"}}>
      <h2 style={{textAlign: "center", color: "#800000",  fontSize: isMobile?10:""}}> Why We Use SQL</h2>
      <hr  style={{
    color: 'darkgreen',
    backgroundColor: 'darkgreen',
    height: isMobile?0.5:3.5,
    borderColor : 'darkgreen',
    width: "55%",
     marginBottom:"3%"
}}/>
           

            <h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
At Algo Avinya, we utilize SQL for database management due to its robustness, efficiency, and reliability in handling structured data. SQL is a powerful language for querying and manipulating relational databases, which allows us to efficiently store, retrieve, and manage large volumes of data. Its standardized syntax ensures consistency and compatibility across various database systems, providing flexibility in choosing the best database solution for our clients' needs.
</h4>
<h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
SQL's ability to handle complex queries and perform data operations quickly makes it ideal for developing applications that require real-time data processing and analytics. With SQL, we can execute intricate joins, filters, and aggregations to derive meaningful insights from the data, enhancing decision-making capabilities for businesses. The language's transactional support ensures data integrity and consistency, which is crucial for maintaining the accuracy and reliability of business-critical applications.
</h4>
<h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
Furthermore, SQL's well-established ecosystem includes robust tools for database design, management, and optimization, enabling us to build efficient and scalable database solutions. Its widespread use and extensive documentation make it easier for our development team to collaborate and maintain the database systems. SQL's security features, such as access controls and encryption, help us protect sensitive data and comply with regulatory requirements.
</h4>
<h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
By leveraging SQL, we ensure that our applications have a solid foundation for data management, offering performance, reliability, and scalability that meet the demands of modern businesses. This commitment to using the best database management practices allows us to deliver high-quality software solutions that drive business success.
</h4>
        </div>
        
      </header>
      
    </div>
  );
}

export default Sql;
