import React from 'react';

import './Header.css';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function WebDevelopment() {
    const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="App">
      <header className="App-header">
        <nav className="nav-container">
          <div className="logo-container">
          <Link to="/" style={{textDecoration: "none"}}>
            <img src={"/main logo.png"} className="App-logo" alt="logo" style={{marginBottom: "-10%"}} />
            <span className="logo-name" style={{fontFamily: "serif"}}>ALGO AVINYA</span>
            </Link>
          </div>
          <ul className="nav-bar" style={{marginTop:isMobile?"0%": "6%", marginRight: "2%"}}>
          
            <li>
            <Link to="/" style={{textDecoration: "none"}}><a href="#home" style={{fontSize:isMobile?8: 22, padding:isMobile?"0px 0px": "10px 10px"}}>Home</a></Link></li>
            
          </ul>
        </nav>

        <div style={{backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      paddingBottom: "10px",padding: "20px 20px 0px 20px", marginRight: "5%", marginLeft: "5%", marginTop: "5%"}}>
      <h2 style={{textAlign: "center", color: "#800000",  fontSize: isMobile?10:""}}>Web Application development</h2>
      <hr  style={{
    color: 'darkgreen',
    backgroundColor: 'darkgreen',
    height: isMobile?0.5:3.5,
    borderColor : 'darkgreen',
    width: "55%",
     marginBottom:"3%"
}}/>
            <h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>The market for web applications is expanding quickly as companies depend more and more on digital platforms for operations and customer interaction. Adoption of cloud computing and SaaS models for effective deployment and scalability are important themes. Because mobile devices are becoming more and more common, responsive web apps are essential. They provide strong analytics for insights based on data, worldwide accessibility, and integration capabilities. At Algo Avinya, we're experts at creating safe, secure online apps that give companies access to cutting-edge technology so they can stay ahead of the competition and accomplish their strategic objectives in the digital era.</h4>
        </div>
        
      </header>
      
    </div>
  );
}

export default WebDevelopment;
