import React from 'react';

import './Header.css';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function MobileDevelopment() {
    const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="App">
      <header className="App-header">
        <nav className="nav-container">
          <div className="logo-container">
          <Link to="/" style={{textDecoration: "none"}}>
            <img src={"/main logo.png"} className="App-logo" alt="logo" style={{marginBottom: "-10%"}} />
            <span className="logo-name" style={{fontFamily: "serif"}}>ALGO AVINYA</span>
            </Link>
          </div>
          <ul className="nav-bar" style={{marginTop: isMobile?"0%":"6%", marginRight: "2%"}}>
          
            <li>
            <Link to="/" style={{textDecoration: "none"}}><a href="#home" style={{fontSize:isMobile?8: 22, padding:isMobile?"0px 0px": "10px 10px"}}>Home</a></Link></li>
            
          </ul>
        </nav>

        <div style={{backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      paddingBottom: "10px",padding: "20px 20px 0px 20px", marginRight: "5%", marginLeft: "5%", marginTop: "5%"}}>
      <h2 style={{textAlign: "center", color: "#800000",  fontSize: isMobile?10:""}}>Mobile Application development</h2>
      <hr  style={{
    color: 'darkgreen',
    backgroundColor: 'darkgreen',
    height: isMobile?0.5:3.5,
    borderColor : 'darkgreen',
    width: "55%",
     marginBottom:"3%"
}}/>
            <h4 style={{textAlign: "justify", fontSize: isMobile?8:""}}>Developing mobile applications under the brand "Algo Avinya" presents a compelling opportunity amidst the pervasive growth of mobile usage worldwide. With a burgeoning market driven by increasing smartphone penetration and evolving consumer behaviors, there is a strong demand for innovative and user-centric mobile solutions across diverse industries. Beyond fulfilling market needs, this venture promises substantial revenue potential through various monetization strategies such as app sales, in-app purchases, subscriptions, and advertising. Moreover, establishing a presence in the mobile app ecosystem can significantly enhance brand visibility and credibility in the competitive tech landscape. By focusing on creating intuitive and impactful applications, "Algo Avinya" can not only attract a loyal user base but also foster deeper engagement through direct interaction, feedback collection, and continuous improvement. This approach not only positions the company strategically within the market but also sets a foundation for sustained growth and differentiation in the dynamic world of mobile technology.</h4>
        </div>
        
      </header>
      
    </div>
  );
}

export default MobileDevelopment;
