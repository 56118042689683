import React from 'react';

import './Header.css';
import { useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

function Python() {
    const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <div className="App">
      <header className="App-header">
        <nav className="nav-container">
          <div className="logo-container">
          <Link to="/" style={{textDecoration: "none"}}>
            <img src={"/main logo.png"} className="App-logo" alt="logo" style={{marginBottom: "-10%"}} />
            <span className="logo-name" style={{fontFamily: "serif"}}>ALGO AVINYA</span>
            </Link>
          </div>
          <ul className="nav-bar" style={{marginTop:  isMobile?"0%":"6%", marginRight: "2%"}}>
          
            <li>
            <Link to="/" style={{textDecoration: "none"}}><a href="#home" style={{fontSize:isMobile?8: 22, padding:isMobile?"0px 0px": "10px 10px"}}>Home</a></Link></li>
            
          </ul>
        </nav>

        <div style={{backgroundColor: "#fff",
      borderRadius: 8,
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      paddingBottom: "10px",padding: "20px 20px 0px 20px", marginRight: "5%", marginLeft: "5%", marginTop: "5%"}}>
      <h2 style={{textAlign: "center", color: "#800000",  fontSize: isMobile?10:""}}>Why We Use Python Django</h2>
      <hr  style={{
    color: 'darkgreen',
    backgroundColor: 'darkgreen',
    height: isMobile?0.5:3.5,
    borderColor : 'darkgreen',
    width: "55%",
     marginBottom:"3%"
}}/>
           

        
            <h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
            
At Algo Avinya, we use Python Django for backend development due to its powerful and efficient capabilities. Django is a high-level web framework that enables rapid development and clean, pragmatic design. Its emphasis on reusability and "don't repeat yourself" (DRY) principles allows us to build robust and scalable web applications quickly. Django's extensive built-in features, such as an authentication system, admin interface, and ORM, reduce the need for third-party libraries, resulting in more secure and maintainable code.
</h4>
   <h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
Django's adherence to best practices and its clear and readable code structure make it easier for developers to understand and modify the codebase, facilitating collaboration and long-term maintenance. The framework's strong community support and comprehensive documentation provide valuable resources and ongoing improvements. Django’s ORM simplifies database interactions, allowing us to write database queries using Python code, which enhances productivity and reduces the likelihood of errors.
</h4>
<h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
Security is a top priority for us, and Django's robust security features, including protection against SQL injection, cross-site scripting (XSS), and cross-site request forgery (CSRF), help us build secure applications. Additionally, Django's scalability ensures that our applications can handle increased traffic and data volumes, making it ideal for growing businesses. Finally, Django's compatibility with Python, a versatile and widely-used programming language, allows us to leverage a vast ecosystem of libraries and tools, enhancing the functionality and performance of our applications.
</h4>
<h4 style={{textAlign: "justify",  fontSize: isMobile?8:""}}>
By choosing Python Django, we ensure that our backend development is efficient, secure, and scalable, ultimately delivering high-quality software solutions to our clients.
</h4>

        </div>
        
      </header>
      
    </div>
  );
}

export default Python;
